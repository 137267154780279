import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "@app/core/services/auth.service";
import { Router } from "@angular/router";
import { PreloaderService } from "@app/core/services/preloader.service";

@Component({
  template: ``,
})
export class LogoutComponent implements OnInit,OnDestroy {
  constructor(private authService: AuthService,
              private preloaderService: PreloaderService,
              private router: Router) {
  }

  ngOnInit() {
    this.preloaderService.show();

    this.authService.logout().then(
      (user) => {
        if (user) {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/']);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.preloaderService.hide();
  }
}
