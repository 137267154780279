import { filter, take, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Credential } from '@app/shared/data/credential/credential.models';
import { FeatureFlaggingEnum, FeatureFlaggingService, ISetting } from '@app/shared/services/feature-flagging/feature-flagging.service';
import { map } from 'rxjs/operators';
import { User } from '@app/shared/data/user/user.models';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { animate, state, style, transition, trigger } from '@angular/animations';

const auth0Enabled = !Boolean(Number(localStorage.getItem('auth0_disabled')));

export enum ItemType {
  COMPETITION = 'COMPETITION',
  AUTOMATION = 'AUTOMATION',
  WALLET = 'WALLET',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CREDENTIALS = 'CREDENTIALS',
  SETTINGS = 'SETTINGS'
}

function observableToBehaviorSubject<T>(
  observable: Observable<T>,
  initValue: T
): BehaviorSubject<T> {
  const subject = new BehaviorSubject<T>(initValue);

  observable.subscribe(subject);

  return subject;
}

export const topMenuItemClassName = 'top-menu-item';

@Component({
  selector: 'st-client-new-sidebar',
  templateUrl: './client-sidebar.component.html',
  styleUrls: ['./client-sidebar.component.scss'],
  animations: [
    trigger('showHide', [
      state('true', style({
        width: '276px',
      })),
      state('false', style({
        width: '62px',
      })),
      transition('* => *', [
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')
      ])
    ])]
})
export class ClientSidebarComponent implements OnInit, OnDestroy {

  @Output() toggledSidebar: EventEmitter<boolean> = new EventEmitter();

  private _destroy: Subject<void> = new Subject<void>();

  public automationSubMenu = {
    title: 'Automation',
    items: {
      lastLaunch: {label: this.i18n('Last Launch'), route: '/last-launch/blast-protocol', enabled: new BehaviorSubject<boolean>(false), async: true},
      promotions: {label: this.i18n('Promotions'), route: '/pms', enabled: new BehaviorSubject<boolean>(false), async: true},
      reviewRequest: {label: this.i18n('Review Request'), route: '/review-request', enabled: new Observable<boolean>(), async: true},
      ppcs: {label: this.i18n('PPCS'), route: '/ppcs/ppc-entourage', enabled: new Observable<boolean>(), enabled2: new Observable<{disabled: boolean, tooltip: string}>(), async: true}
    }
  };
  public automationRoutes: string[] = [];

  public competitionSubMenu: any = [];
  public competitionRoutes: string[] = [];
  public walletSubMenu = {
    title: 'Wallet',
    items: {
      transactions: {label: this.i18n('Transactions'), route: '/wallet', enabled: true},
      blacklist: {label: this.i18n('Blacklist'), route: '/wallet/blacklist', enabled: new BehaviorSubject<boolean>(false), async: true},
    }
  };
  public walletRoutes: string[] = [];

  public settingsSubMenu = {
    title: 'Settings',
    items: {
      admin: {label: this.i18n('Admin Console'), route: '/admin', enabled: false},
      account: {label: this.i18n('My Account'), route: '/profile', enabled: true},
      subAccount: {label: this.i18n('Sub Accounts'), route: '/credentials', enabled: false},
      credentials: {label: this.i18n('Credentials'), route: '/credentials', enabled: true},
      affiliate: {label: this.i18n('Affiliate Program'), route: '/affiliation', enabled: true},
      billing: {label: this.i18n('Billing'), route: '/billing', enabled: true},
      api: {label: this.i18n('Set Up API'), route: '/api-keys', enabled: new BehaviorSubject<boolean>(false), async: true},
      sandbox: {label: this.i18n('Sandbox'), route: '/api/docs', enabled: new BehaviorSubject<boolean>(false), async: true},
      logout: {
        label: this.i18n('Log Out'),
        route: '/auth/logout',
        enabled: !auth0Enabled,
        async: false,
        icon: 'logout',
        highlight: true
      },
    }
  };
  public settingsRoutes: string[] = [];

  public competitionResearchEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public customerListEnabled$: BehaviorSubject<boolean> = observableToBehaviorSubject(
    this.authService.userHasPermission$('customer.create'),
    false
  );
  public enableWallet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public campaignManagerEnabled$: BehaviorSubject<boolean> = observableToBehaviorSubject(
    this.authService.userHasPermission$('campaigns.write'),
    false
  );
  public enableAlerts$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public keywordInsightsAccess$: Observable<boolean> = this.authService.ownerHasPermission$('keyword-insights.access');
  public enableCredentials = true;

  private localStorageExpandedName = 'sidebar_status';

  itemTypes = ItemType;
  selectedItem: string = null;
  activeRoute: string = null;
  activeCredential: Credential;
  isSidebarExpanded = true;
  currentWidth$: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  previousWidth: number = window.innerWidth;

  hasNewR2A: boolean;

  constructor(private router: Router,
              private authService: AuthService,
              private featureFlaggingService: FeatureFlaggingService,
              private i18n: I18n) {
  }

  ngOnInit() {
    this.featureFlaggingService.getFeatureFlag(FeatureFlaggingEnum.NEW_R2A)
      .subscribe((flag) => {
        this.hasNewR2A = flag?.settingValue;

        const r2aMenu = this.hasNewR2A
          ? {
            kw: {label: this.i18n('KW Autocomplete'), route: '/last-search', enabled: true},
          }
          : {
            r2a: {
              label: this.i18n('R2A'),
              route: '/r2a',
              enabled: true
            },
            kw: {label: this.i18n('KW Autocomplete'), route: '/last-search', enabled: true},
          }

        this.competitionSubMenu = {
          title: 'Competition Research',
          items: r2aMenu
        };
      });

    Object.values(this.automationSubMenu.items).forEach(e => this.automationRoutes.push(e.route));
    Object.values(this.competitionSubMenu.items).forEach(e => this.competitionRoutes.push((e as any).route));
    Object.values(this.settingsSubMenu.items).forEach(e => this.settingsRoutes.push(e.route));
    Object.values(this.walletSubMenu.items).forEach(e => this.walletRoutes.push(e.route));

    this.authService.activeCredentials$().pipe(
      takeUntil(this._destroy))
        .subscribe((credential: Credential) => {
          this.activeCredential = credential;
        });

    this.initFlags();
    this.initRoutes();
    this.checkForSavedState();

    this.router.events.pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => this.setActive(event.url));

    this.currentWidth$.subscribe((event) => {
      if (this.previousWidth <= 1300 && event > 1300) {
        this.toggleNavigation(true);
      } else if (this.previousWidth > 1300 && event <= 1300) {
        this.toggleNavigation(false);
      }
      this.previousWidth = event;
    });
    this.setActive(this.router.url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentWidth$.next(event.target.innerWidth);
  }

  checkForSavedState() {
    const sidebarExpandedStatus = localStorage.getItem(this.localStorageExpandedName) === 'true';

    this.toggleNavigation(sidebarExpandedStatus);
  }

  initFlags() {
    this.settingsSubMenu.items.admin.enabled = this.authService.userHasPermission('admin.super');
    this.settingsSubMenu.items.subAccount.enabled = this.authService.isActiveUserOwnerActiveCredential() && Number.isInteger(this.activeCredential.id);
    this.automationSubMenu.items.reviewRequest.enabled = this.authService.ownerHasPermission$('review-request');
    this.automationSubMenu.items.ppcs.enabled = this.authService.ownerHasPermission$('samurai-service.access');

    this.authService.activeCredentials$().pipe(
      takeUntil(this._destroy))
      .subscribe((credential: Credential) => {
        if (!credential.id) {
          this.automationSubMenu.items.lastLaunch.enabled.next(false);
          this.competitionResearchEnabled$.next(false);
          this.enableAlerts$.next(false);
          this.settingsSubMenu.items.api.enabled.next(false);
          this.enableWallet$.next(false);
        } else {
          const extension = credential.amazonMarketplace.domain.replace('amazon.', '').replace('.', '-');
          const lastLaunchSlug = 'lastlaunchpromotion-' + extension + '.access';
          this.automationSubMenu.items.lastLaunch.enabled.next(this.authService.hasPermissions(lastLaunchSlug, 'sub-acc.last-launch'));
          this.automationSubMenu.items.promotions.enabled.next(true);
          this.competitionResearchEnabled$.next(this.authService.userHasPermission('kwgen.' + credential.amazon_domain));
          this.enableAlerts$.next(this.authService.hasPermissions('alerts.access', 'sub-acc.alerts'));
          this.settingsSubMenu.items.api.enabled.next(this.authService.hasPermissions('api-menu.access'));
          this.settingsSubMenu.items.sandbox.enabled.next(this.authService.hasPermissions('api-menu.access'));
          this.enableWallet$.next(this.authService.isOwnerOrHasSubAccountPermission('sub-acc.wallet')
            && (new Date(this.authService.getUser().created_at) < new Date('2023-09-26T00:00:00.000Z')
            || this.authService.getUser().is_wallet_verified));
          this.walletSubMenu.items.blacklist.enabled.next(this.authService.hasPermissions('blacklist'));
          }
        });

    this.automationSubMenu.items.ppcs.enabled2 = this.authService.getUser$().pipe(map((user: User) => {
      if (user.plan_name === 'Trail') {
        return {
          disabled: false,
          tooltip: this.i18n('Limited PPCS access is available in your Trial - Upgrade to continue your access')
        };
      }
      if (user.plan_name === 'Starter') {
        return {
          disabled: false,
          tooltip: this.i18n('Upgrade to Pro plan to unlock additional PPCS features like Tasks and Bulk Keyword Editor')
        };
      }
      if (user.plan_name === 'Lite' || user.plan_name === 'Free') {
        return {
          disabled: true,
          tooltip: this.i18n('Upgrade to Starter plan or above to gain access to PPCS')
        };
      }

      return {disabled: false, tooltip: null};
    }));
  }

  initRoutes() {
    if (this.authService.isActiveUserOwnerActiveCredential() && this.activeCredential) {
      this.settingsSubMenu.items.subAccount.route = '/credentials/' + this.activeCredential.id + '/sub-accounts';
    }
  }

  setActive(url: string) {
    if (this.activeCredential && !this.activeCredential.id && url.includes('credentials')) {
      this.activeRoute = this.itemTypes.CREDENTIALS;
    } else if (this.automationRoutes.includes(url)) {
      this.activeRoute = this.itemTypes.AUTOMATION;
    } else if (this.walletRoutes.includes(url)) {
      this.activeRoute = this.itemTypes.WALLET;
    } else if (this.competitionRoutes.includes(url)) {
      this.activeRoute = this.itemTypes.COMPETITION;
    } else if (this.settingsRoutes.includes(url)) {
      this.activeRoute = this.itemTypes.SETTINGS;
    } else {
      this.activeRoute = null;
    }
  }

  selectItem(item: ItemType) {
    if (item === this.selectedItem) {
      if(this.isSidebarExpanded) {
        this.selectedItem = null;
      }
      return;
    }
    this.selectedItem = item;
    this.setSubmenuPosition();
  }

  setSubmenuPosition() {
    if (!this.selectedItem) {
      return;
    }
    setTimeout(() => {
      const wrapper: HTMLElement = document.querySelector('.sidebar-wrapper');
      const parent: HTMLElement = document.querySelector('div.selected');

      if (parent) {
        const submenu: HTMLElement = document.querySelector('.submenu');
        if (!submenu) {
          return;
        }

        const navOffset = (<HTMLElement>wrapper?.offsetParent)?.offsetTop;
        if (submenu.classList.contains('top')) {
          submenu.style.bottom = window.innerHeight - (parent.offsetTop - wrapper?.scrollTop) - (navOffset + 52)  + 'px';
        } else {
          submenu.style.top = (parent.offsetTop - wrapper?.scrollTop) + (navOffset) + 'px';
        }
        submenu.style.left = parent.offsetWidth + 20 + 'px';
      }
    },0);
  }

  reset() {
    this.selectedItem = null;
  }

  toggleNavigation(toggle?: boolean) {
    if (toggle === undefined) {
      this.isSidebarExpanded = !this.isSidebarExpanded;
    } else {
      this.isSidebarExpanded = toggle;
    }
    this.selectedItem = null;
    this.toggledSidebar.emit(this.isSidebarExpanded);

    localStorage.setItem(this.localStorageExpandedName, this.isSidebarExpanded.toString());
  }

  stopPropagation(event: Event) {
    event.stopImmediatePropagation();
  }

  addCredentials() {
    this.router.navigate(['/', 'credentials']);
  }

  onCredentialsClick() {
    if (!this.activeCredential?.id) {
      this.addCredentials();
      return;
    }

    if (!this.isSidebarExpanded) {
      return;
    }

    this.selectItem(this.itemTypes.CREDENTIALS);
  }


  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
